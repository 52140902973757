import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1263ff11"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "row mb-10" }
const _hoisted_4 = { class: "col span-6" }
const _hoisted_5 = { class: "col span-6" }
const _hoisted_6 = { class: "row mb-10" }
const _hoisted_7 = { class: "col span-6" }
const _hoisted_8 = { class: "col span-6" }
const _hoisted_9 = { class: "row mb-10" }
const _hoisted_10 = { class: "col span-6" }
const _hoisted_11 = { class: "col span-6" }
const _hoisted_12 = { class: "row mb-10" }
const _hoisted_13 = { class: "col span-6 checkbox-column" }
const _hoisted_14 = { class: "row mb-10" }
const _hoisted_15 = { class: "col span-6 checkbox-column" }
const _hoisted_16 = { class: "row mb-10" }
const _hoisted_17 = {
  key: 0,
  class: "col span-3 mt-15"
}
const _hoisted_18 = {
  key: 1,
  class: "col span-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_KeyValue = _resolveComponent("KeyValue")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabeledInput, {
          mode: _ctx.mode,
          "label-key": "gke.clusterIpv4Cidr.label",
          value: _ctx.clusterIpv4Cidr,
          placeholder: _ctx.t('gke.clusterIpv4Cidr.placeholder'),
          disabled: !_ctx.isNewOrUnprovisioned,
          rules: _ctx.rules.clusterIpv4Cidr,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:clusterIpv4Cidr', $event)))
        }, null, 8, ["mode", "value", "placeholder", "disabled", "rules"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.selectedNetwork,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNetwork) = $event)),
          options: _ctx.networkOptions,
          mode: _ctx.mode,
          "label-key": "gke.network.label",
          "option-key": "name",
          "option-label": "label",
          disabled: !_ctx.isNewOrUnprovisioned,
          "data-testid": "gke-networks-dropdown"
        }, null, 8, ["value", "options", "mode", "disabled"])
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.selectedSubnetwork,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSubnetwork) = $event)),
          options: _ctx.subnetworkOptions,
          "option-key": "name",
          "option-label": "label",
          mode: _ctx.mode,
          "label-key": _ctx.useIpAliases ? 'gke.subnetwork.nodeLabel' : 'gke.subnetwork.label',
          disabled: !_ctx.isNewOrUnprovisioned,
          "data-testid": "gke-subnetworks-dropdown"
        }, null, 8, ["value", "options", "mode", "label-key", "disabled"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (!!_ctx.subnetwork)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              value: _ctx.selectedClusterSecondaryRangeName,
              mode: _ctx.mode,
              options: _ctx.clusterSecondaryRangeOptions,
              "label-key": "gke.clusterSecondaryRangeName.label",
              disabled: !_ctx.isNewOrUnprovisioned,
              "data-testid": "gke-cluster-secondary-range-name-select",
              "onUpdate:value": _cache[3] || (_cache[3] = e=>_ctx.selectedClusterSecondaryRangeName = e)
            }, null, 8, ["value", "mode", "options", "disabled"]))
          : (_openBlock(), _createBlock(_component_LabeledInput, {
              key: 1,
              mode: _ctx.mode,
              value: _ctx.subnetworkName,
              "label-key": "gke.subnetwork.name",
              placeholder: _ctx.t('gke.subnetwork.namePlaceholder'),
              disabled: !_ctx.isNewOrUnprovisioned,
              "data-testid": "gke-subnetwork-name-input",
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:subnetworkName', $event)))
            }, null, 8, ["mode", "value", "placeholder", "disabled"]))
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.disableClusterIpv4CidrBlock ? _ctx.selectedClusterSecondaryRangeName.ipCidrRange : _ctx.clusterIpv4CidrBlock,
          mode: _ctx.mode,
          "label-key": "gke.clusterIpv4CidrBlock.label",
          placeholder: _ctx.t('gke.clusterIpv4Cidr.placeholder'),
          disabled: _ctx.disableClusterIpv4CidrBlock,
          rules: _ctx.rules.clusterIpv4CidrBlock,
          "data-testid": "gke-cluster-secondary-range-cidr-input",
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:clusterIpv4CidrBlock', $event)))
        }, null, 8, ["value", "mode", "placeholder", "disabled", "rules"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        (!!_ctx.subnetwork)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              value: _ctx.selectedServicesSecondaryRangeName,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedServicesSecondaryRangeName) = $event)),
              mode: _ctx.mode,
              options: _ctx.clusterSecondaryRangeOptions,
              "label-key": "gke.servicesSecondaryRangeName.label",
              disabled: !_ctx.isNewOrUnprovisioned
            }, null, 8, ["value", "mode", "options", "disabled"]))
          : (_openBlock(), _createBlock(_component_LabeledInput, {
              key: 1,
              mode: _ctx.mode,
              value: _ctx.nodeIpv4CidrBlock,
              "label-key": "gke.nodeIpv4CidrBlock.label",
              disabled: !_ctx.isNewOrUnprovisioned,
              rules: _ctx.rules.nodeIpv4CidrBlock,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:nodeIpv4CidrBlock', $event)))
            }, null, 8, ["mode", "value", "disabled", "rules"]))
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_LabeledInput, {
          value: _ctx.disableServicesIpv4CidrBlock ? _ctx.selectedClusterSecondaryRangeName.ipCidrRange : _ctx.servicesIpv4CidrBlock,
          mode: _ctx.mode,
          "label-key": "gke.servicesIpv4CidrBlock.label",
          placeholder: _ctx.t('gke.clusterIpv4Cidr.placeholder'),
          disabled: _ctx.disableServicesIpv4CidrBlock,
          rules: _ctx.rules.servicesIpv4CidrBlock,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:servicesIpv4CidrBlock', $event)))
        }, null, 8, ["value", "mode", "placeholder", "disabled", "rules"])
      ])
    ]),
    _createTextVNode(),
    (!_ctx.useIpAliases)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          class: "mb-10",
          "label-key": "gke.useIpAliases.warning",
          color: "warning",
          "data-testid": "gke-use-ip-aliases-banner"
        }))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Checkbox, {
          value: _ctx.useIpAliases,
          mode: _ctx.mode,
          label: _ctx.t('gke.useIpAliases.label'),
          disabled: !_ctx.isNewOrUnprovisioned,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('update:useIpAliases', $event)))
        }, null, 8, ["value", "mode", "label", "disabled"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.networkPolicyConfig,
          mode: _ctx.mode,
          label: _ctx.t('gke.networkPolicyConfig.label'),
          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('update:networkPolicyConfig', $event)))
        }, null, 8, ["value", "mode", "label"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.networkPolicyEnabled,
          mode: _ctx.mode,
          label: _ctx.t('gke.networkPolicyEnabled.label'),
          disabled: !_ctx.isNewOrUnprovisioned,
          "onUpdate:value": _cache[11] || (_cache[11] = e=>_ctx.updateNetworkPolicyEnabled(e))
        }, null, 8, ["value", "mode", "label", "disabled"]),
        _createTextVNode(),
        _createVNode(_component_Checkbox, {
          value: _ctx.enableNetworkPolicy,
          mode: _ctx.mode,
          label: _ctx.t('gke.enableNetworkPolicy.label'),
          "onUpdate:value": _cache[12] || (_cache[12] = e=>_ctx.updateEnableNetworkPolicy(e))
        }, null, 8, ["value", "mode", "label"])
      ])
    ]),
    _createTextVNode(),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        type: "button",
        class: "btn role-link advanced-toggle mb-0",
        onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.toggleAdvanced && _ctx.toggleAdvanced(...args)))
      }, _toDisplayString(_ctx.showAdvanced ? _ctx.t('gke.hideAdvanced') : _ctx.t('gke.showAdvanced')), 1)
    ]),
    _createTextVNode(),
    (_ctx.showAdvanced)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_Banner, {
            color: "warning",
            "label-key": "gke.enablePrivateNodes.warning"
          }),
          _createTextVNode(),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_Checkbox, {
                mode: _ctx.mode,
                label: _ctx.t('gke.enablePrivateNodes.label'),
                value: _ctx.enablePrivateNodes,
                disabled: !_ctx.isNewOrUnprovisioned,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('update:enablePrivateNodes', $event)))
              }, null, 8, ["mode", "label", "value", "disabled"]),
              _createTextVNode(),
              _createVNode(_component_Checkbox, {
                mode: _ctx.mode,
                label: _ctx.t('gke.enablePrivateEndpoint.label'),
                value: _ctx.enablePrivateEndpoint,
                disabled: !_ctx.enablePrivateNodes || !_ctx.isNewOrUnprovisioned,
                tooltip: _ctx.t('gke.enablePrivateEndpoint.tooltip'),
                "data-testid": "gke-enable-private-endpoint-checkbox",
                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('update:enablePrivateEndpoint', $event)))
              }, null, 8, ["mode", "label", "value", "disabled", "tooltip"]),
              _createTextVNode(),
              _createVNode(_component_Checkbox, {
                mode: _ctx.mode,
                value: _ctx.enableMasterAuthorizedNetwork,
                label: _ctx.t('gke.masterAuthorizedNetwork.enable.label'),
                disabled: _ctx.enablePrivateEndpoint || !_ctx.isNewOrUnprovisioned,
                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => (_ctx.$emit('update:enableMasterAuthorizedNetwork', $event)))
              }, null, 8, ["mode", "value", "label", "disabled"])
            ])
          ]),
          _createTextVNode(),
          _createElementVNode("div", _hoisted_16, [
            (_ctx.enablePrivateNodes)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_LabeledInput, {
                    mode: _ctx.mode,
                    value: _ctx.masterIpv4CidrBlock,
                    "label-key": "gke.masterIpv4CidrBlock.label",
                    placeholder: _ctx.t('gke.masterIpv4CidrBlock.placeholder'),
                    tooltip: _ctx.t('gke.masterIpv4CidrBlock.tooltip'),
                    disabled: !_ctx.isNewOrUnprovisioned,
                    required: "",
                    rules: _ctx.rules.masterIpv4CidrBlock,
                    "data-testid": "gke-master-ipv4-cidr-block-input",
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => (_ctx.$emit('update:masterIpv4CidrBlock', $event)))
                  }, null, 8, ["mode", "value", "placeholder", "tooltip", "disabled", "rules"])
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(),
            (_ctx.enableMasterAuthorizedNetwork)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(_component_KeyValue, {
                    label: _ctx.t('gke.masterAuthorizedNetwork.cidrBlocks.label'),
                    mode: _ctx.mode,
                    "as-map": false,
                    "key-name": "displayName",
                    "value-name": "cidrBlock",
                    "key-label": _ctx.t('gke.masterAuthorizedNetwork.cidrBlocks.displayName'),
                    "value-label": _ctx.t('gke.masterAuthorizedNetwork.cidrBlocks.cidr'),
                    "value-placeholder": _ctx.t('gke.clusterIpv4Cidr.placeholder'),
                    value: _ctx.masterAuthorizedNetworkCidrBlocks,
                    "read-allowed": false,
                    "add-label": _ctx.t('gke.masterAuthorizedNetwork.cidrBlocks.add'),
                    "initial-empty-row": true,
                    "data-testid": "gke-master-authorized-network-cidr-keyvalue",
                    "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('update:masterAuthorizedNetworkCidrBlocks', $event)))
                  }, null, 8, ["label", "mode", "key-label", "value-label", "value-placeholder", "value", "add-label"])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}