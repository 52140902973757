import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["checked", "value", "name"]
const _hoisted_3 = ["tabindex", "aria-label", "aria-checked"]
const _hoisted_4 = {
  key: 2,
  class: "checkbox-info icon icon-info icon-lg"
}
const _hoisted_5 = {
  key: 3,
  class: "checkbox-info icon icon-info icon-lg"
}
const _hoisted_6 = {
  key: 0,
  class: "checkbox-outer-container-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")!
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkbox-outer-container", {
      'v-popper--has-tooltip': _ctx.hasTooltip,
    }]),
    "data-checkbox-ctrl": ""
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["checkbox-container", { 'disabled': _ctx.isDisabled}]),
      for: _ctx.id,
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.clicked($event)), ["prevent"]), ["enter"])),
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.clicked($event)), ["prevent"]), ["space"]))
      ],
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clicked($event)))
    }, [
      _createElementVNode("input", {
        checked: _ctx.isChecked,
        value: _ctx.valueWhenTrue,
        type: "checkbox",
        tabindex: -1,
        name: _ctx.id,
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"]))
      }, null, 8, _hoisted_2),
      _createTextVNode(),
      _createElementVNode("span", {
        class: _normalizeClass(["checkbox-custom", {indeterminate: _ctx.indeterminate}]),
        tabindex: _ctx.isDisabled ? -1 : 0,
        "aria-label": _ctx.label,
        "aria-checked": !!_ctx.value,
        role: "checkbox"
      }, null, 10, _hoisted_3),
      _createTextVNode(),
      (_ctx.$slots.label || _ctx.label || _ctx.labelKey || _ctx.hasTooltip)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["checkbox-label", { 'checkbox-primary': _ctx.primary }])
          }, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              (_ctx.labelKey)
                ? (_openBlock(), _createBlock(_component_t, {
                    key: 0,
                    k: _ctx.labelKey,
                    raw: true
                  }, null, 8, ["k"]))
                : (_ctx.label)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.label), 1)
                    ], 64))
                  : _createCommentVNode("", true),
              _createTextVNode(),
              (_ctx.tooltipKey)
                ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_4, null, 512)), [
                    [_directive_clean_tooltip, _ctx.t(_ctx.tooltipKey)]
                  ])
                : (_ctx.tooltip)
                  ? _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_5, null, 512)), [
                      [_directive_clean_tooltip, _ctx.tooltip]
                    ])
                  : _createCommentVNode("", true)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ], 42, _hoisted_1),
    _createTextVNode(),
    (_ctx.descriptionKey || _ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.descriptionKey)
            ? (_openBlock(), _createBlock(_component_t, {
                key: 0,
                k: _ctx.descriptionKey
              }, null, 8, ["k"]))
            : (_ctx.description)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.description), 1)
                ], 64))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}